import { Footer } from 'src/layout/Footer'
import { Product, ProductItem } from 'src/layout/Product'
import { firebaseLog } from 'src/service/firebase'

export const Products = () => {

  firebaseLog('view_item_list', {})

  const products: ProductItem[] = [
    {
      id: 'web3-api-service',
      type: 'service',
      name: 'Web3 API 서비스',
      imageUrl: '/images/web3-api-service.jpg',
      options: [
        { name: '5M', value: '₩ 300,000' },
        { name: '1M', value: '₩ 70,000' },
        { name: '사용량 초과시 1M당', value: '₩ 70,000' },
      ],
      description:
        '- Web3 연계 서비스를 바로 구현할 수 있습니다\n' +
        '  별도 인프라 구축 및 노드 동기화 대기시간 없음\n' +
        '  플랫폼 추가/변경 비용 없이 적용 가능\n' +
        '  필요시에만 테스트 네트워크를 이용하여 비용 절감 가능\n' +
        '  월간 사용량 제한없이 월단위 사용량 확장/축소 가능\n' +
        '  (※ 원활한 서비스 제공을 위해 사전 협조 부탁드립니다)\n' +
        '\n' +
        '- JSON RPC Gateway\n' +
        '- Customized Web3 API\n' +
        '  서비스 구현시 필수 기능이 준비된 통합 API\n' +
        '  목표 서비스에 맞춤 API 개발 협의 가능\n' +
        '\n' +
        '- 즉시 이용 가능한 플랫폼과 네트워크는 다음과 같습니다\n' +
        '  Ethereum\n' +
        '  - mainnet, goerli\n' +
        '  BSC (BNB Smart Chain)\n' +
        '  - mainnet, testnet\n' +
        '  Polygon\n' +
        '  - mainnet, mumbai\n' +
        '  Solana\n' +
        '  - mainnet-beta, devnet\n' +
        '  (추가 네트워크 요청이 가능합니다)\n' +
        '\n' +
        '- API 사용량에 따라 과금됩니다\n' +
        '  - RPC 연동 API: JSON RPC 호출 수\n' +
        '  - RPC 노드에 연동되지 않는 API: API 호출 수\n' +
        '- 1M = 사용량 1백만회를 의미합니다\n' +
        '- 첫 결제일 기준으로 매월 정기 결제됩니다\n' +
        '  결제일 변경은 문의 부탁드립니다\n' +
        '- 사용량 초과분은 다음 결제시 합산 청구됩니다\n' +
        '  (※ 5M 초과시마다 즉시 청구될 수 있습니다)\n' +
        '- 결제 후 접속 정보가 제공됩니다\n' +
        '- API 사용기록이 없는 경우에 한하여 7일 이내 환불이 가능합니다\n' +
        '\n'
    }
  ]

  return (
    <>
      <header className="text-center mt-10 text-2xl">
        <h2 className="font-semibold">서비스 소개</h2>
      </header>
      <article className="pt-0 mb-0 shadow-none">
        <strong>URARA.IO</strong>는 API 제공 서비스입니다.
      </article>
      <article className="mt-0 pt-0 shadow-none">
        { products.map(product => {
          return <Product key={ product.name } item={ product } />
        }) }
      </article>
      <Footer />
    </>)
}