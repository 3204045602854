import { Footer } from 'src/layout/Footer'
import { getAuth, signup } from 'src/service/api.service'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { setAuth } from 'src/Auth'
import { firebaseLog } from 'src/service/firebase'

export const Signup = () => {
  const [ email, setEmail ] = useState("")
  const [ password, setPassword ] = useState("")
  const [ agreed, setAgreed ] = useState([ false, false, false ])

  const onSignUp = async () => {
    if (email.length === 0 || password.length === 0)
      return

    firebaseLog('sign_up', { method: 'email' })
    if (await signup({ email, password }))
      setAuth(getAuth())
  }

  const isAllAgreed = () => agreed.reduce((p, c) => p && c)

  return (
    <main className="container">
      <header className="text-center mt-10 text-2xl">
        <h2 className="font-[Montserrat] font-semibold">DARKO Studio Dashboard</h2>
      </header>
      <article className="shadow-none">
        <form className="sm:w-1/2 sm:mx-auto">
          <label className="pb-2">가입 정보를 입력해주세요</label>

          <input type="email"
                 className="form-input rounded" placeholder="이메일"
                 value={ email }
                 onChange={ ({ target: { value } }) => setEmail(value) }
          />
          <input type="password"
                 className="form-input rounded" placeholder="비밀번호"
                 value={ password }
                 onChange={ ({ target: { value } }) => setPassword(value) }
          />
          <label className="pt-6">
            <input type="checkbox"
                   className="form-checkbox rounded"
                   checked={ agreed[0] }
                   onChange={ ({ target: { checked } }) => setAgreed([ checked, agreed[1], agreed[2] ]) }
            />
            만 14세 이상입니다
          </label>
          <label className="py-4">
            <input type="checkbox"
                   className="form-checkbox rounded"
                   checked={ agreed[1] }
                   onChange={ ({ target: { checked } }) => setAgreed([ agreed[0], checked, agreed[2] ]) }
            />
            <Link to="/terms.html" target="_blank" className="font-semibold">[이용약관]</Link>에 동의합니다
          </label>
          <label className="pb-6">
            <input type="checkbox"
                   className="form-checkbox rounded"
                   checked={ agreed[2] }
                   onChange={ ({ target: { checked } }) => setAgreed([ agreed[0], agreed[1], checked ]) }
            />
            <Link to="/privacy.html" target="_blank" className="font-semibold">[개인정보 처리방침]</Link>에 동의합니다
          </label>

          <button type="button"
                  className="border-2 border-gray-600 font-semibold py-2"
                  disabled={ !isAllAgreed() }
                  onClick={ onSignUp }
          >
            회원가입
          </button>

          <div className="py-4 flex">
            <Link className="flex-1" to="/auth/login">로그인</Link>
          </div>
        </form>
      </article>
      <Footer />
    </main>)
}