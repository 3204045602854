import { firebaseLog } from 'src/service/firebase'
import { getTossPayments } from 'src/service/toss.service'

export type ProductItem = {
  id: string
  type: string
  name: string
  imageUrl: string
  options: { name: string, value: string }[]
  description: string
}

export const Product = ({ item }: { item: ProductItem }) => {

  const onSelect = async () => {
    firebaseLog('select_content', {
      content_type: item.type,
      item_id: item.id
    })

    const tossPayments = await getTossPayments()
    if (!tossPayments)
      return

    await tossPayments.requestBillingAuth('카드', {
      customerKey: '',
      customerName: '',
      customerEmail: '',
      successUrl: '',
      failUrl: ''
    })
  }

  return (
    <a href="#"
       className="flex flex-col bg-black hover:bg-black items-center md:items-start bg-white border rounded-lg shadow-md md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
      <img className="object-cover rounded-t-lg w-full h-auto md:w-48 md:h-auto md:rounded-none md:rounded-l-lg"
           src={ item.imageUrl } alt={ item.name } />
      <div className="flex flex-col bg-white dark:bg-black w-full justify-between p-4 leading-normal">
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          { item.name }
        </h5>
        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400 whitespace-pre-wrap">
          { item.description }
        </p>
        <p className='text-right'>서비스 이용료 (부가세별도)</p>
        {item.options.map(({name, value}) => {
          return <p className='text-right' key={item.name + name}>{name}: <span className='inline-block w-28'>{value}</span></p>
        })}
      </div>
    </a>
  )
}