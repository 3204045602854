import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { Auth } from 'src/Auth'
import { Dashboard } from 'src/page/dashboard/Dashboard'

// export let colorScheme: 'dark' | 'light' | undefined, setColorScheme: Function

function App() {
  // [ colorScheme, setColorScheme ] = useState()
  //
  // useEffect(() => {
  //   window.matchMedia('(prefers-color-scheme: dark)')
  //   .addEventListener('change', event => {
  //     const colorScheme = event.matches ? "dark" : "light"
  //     console.log(colorScheme) // "dark" or "light"
  //     setColorScheme(colorScheme)
  //   })
  // }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={ <Auth><Navigate to="/dashboard" /></Auth> } />
        <Route path="/auth/*" element={ <Auth><Navigate to="/dashboard" /></Auth> } />
        <Route path="/dashboard/*" element={ <Auth><Dashboard /></Auth> } />
      </Routes>
    </BrowserRouter>
  )
}

export default App
