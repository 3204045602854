import React from 'react'

export function Footer() {
  return (
    <footer className="mb-8">
      <div className="text-center py-4">
        <p>Copyright 2022. DARKO Studio</p>
      </div>
      <div className="mx-auto pb-4 w-fit">
        <small>
          <strong>다르코 스튜디오 (DARKO Studio)</strong><br />
          대표자: 손창환 | 사업자등록번호: 417−42−00834<br />
          <a className="no-underline" target="_blank" rel="noreferrer"
             href="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=4174200834">
            통신판매업신고번호: 2022-서울구로-0109 <span className='font-semibold'>[사업자정보확인]</span>
          </a><br />
          주소: 서울특별시 구로구 디지털로33길 48(대륭포스트타워7차) 305-P141호<br />
          개인정보관리책임: 손창환({ `privacy@{darko.studio, urara.io}` })<br />
          고객센터: 0507−1328−0503 | 문의처: { `help@{darko.studio, urara.io}` }
        </small>
      </div>
    </footer>)
}