import React, { PropsWithChildren, useState } from 'react'
import { getAuth } from 'src/service/api.service'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Login } from 'src/page/auth/Login'
import { Reset } from 'src/page/auth/Reset'
import { Signup } from 'src/page/auth/Signup'

export let auth: any, setAuth: any

export const Auth = ({ children }: PropsWithChildren) => {
  [ auth, setAuth ] = useState(getAuth())

  if (auth)
    return (<>{ children }</>)

  return (
    <Routes>
      <Route path="/*" element={ <Navigate to="/auth/login" /> } />
      <Route path="login" element={ <Login /> } />
      <Route path="reset" element={ <Reset /> } />
      <Route path="signup" element={ <Signup /> } />
    </Routes>
  )
}
