import { Footer } from 'src/layout/Footer'
import { getAuth, login } from 'src/service/api.service'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { setAuth } from 'src/Auth'
import { firebaseLog } from 'src/service/firebase'

export const Login = () => {
  const [ email, setEmail ] = useState("")
  const [ password, setPassword ] = useState("")

  const onLogin = async () => {
    if (email.length === 0 || password.length === 0)
      return

    firebaseLog('login', { method: 'email' })
    if(await login({ email, password }))
      setAuth(getAuth())
  }

  return (
    <main className="container">
      <header className="text-center mt-10 text-2xl">
        <h2 className="font-[Montserrat] font-semibold">DARKO Studio Dashboard</h2>
      </header>
      <article className="shadow-none">
        <form className="sm:w-1/2 sm:mx-auto">
          <label className="pb-2">로그인 정보를 입력해주세요</label>

          <input type="email"
                 className="form-input rounded" placeholder="이메일"
                 value={ email }
                 onChange={ ({ target: { value } }) => setEmail(value) }
          />
          <input type="password"
                 className="form-input rounded" placeholder="비밀번호"
                 value={ password }
                 onChange={ ({ target: { value } }) => setPassword(value) }
                 onKeyUp={ ({ code }) => (code === 'Enter' ? onLogin() : undefined) }
          />

          <button type="button" className="border-2 border-gray-600 font-semibold py-2" onClick={ onLogin }>
            로그인
          </button>

          <div className="py-4 flex">
            <Link className="flex-1" to="/auth/reset">비밀번호 재설정</Link>
            <Link className="flex-1 text-right" to="/auth/signup">회원가입</Link>
          </div>
        </form>
      </article>
      <Footer />
    </main>)
}