import { FirebaseError } from '@firebase/util'
import { FirebaseApp, initializeApp } from 'firebase/app'
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword
} from 'firebase/auth'
import { EventParams, getAnalytics, logEvent } from '@firebase/analytics'

let firebaseApp: FirebaseApp
const initialize = () => {
  const config = process.env.REACT_APP_FIREBASE_CONFIG
  if (!config) return undefined

  firebaseApp = initializeApp(JSON.parse(config))
}
initialize()

export interface AuthRequestData {
  email: string
  password: string
}

export interface AuthResponseData {
  user: {
    email: string
    emailVerified: boolean
  },
  token?: string
}

const throwError = (e: unknown) => {
  if (e instanceof FirebaseError)
    throw new Error(e.code)
  else if (e instanceof Error)
    throw new Error(e.message)
  else
    throw new Error('unknown')
}

export const firebaseLogin = async ({ email, password }: AuthRequestData) => {
  const auth = getAuth(firebaseApp)

  try {
    return await signInWithEmailAndPassword(auth, email, password)
  } catch (e) {
    throwError(e)
  }
}

export const firebaseCreateUser = async ({ email, password }: AuthRequestData) => {
  const auth = getAuth(firebaseApp)

  try {
    const credential = await createUserWithEmailAndPassword(auth, email, password)

    if (!credential.user.emailVerified)
      await sendEmailVerification(credential.user)

    return credential
  } catch (e) {
    throwError(e)
  }
}

export const firebasePasswordReset = async (email: string) => {
  const auth = getAuth(firebaseApp)

  try {
    await sendPasswordResetEmail(auth, email)
  } catch (e) {
    throwError(e)
  }
}

export const firebaseLog = (eventName: any, eventParams: EventParams) => {
  const analytics = getAnalytics(firebaseApp)
  logEvent(analytics, eventName, eventParams)
}