import { Footer } from 'src/layout/Footer'
import { authReset } from 'src/service/api.service'
import { Link, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { firebaseLog } from 'src/service/firebase'

export const Reset = () => {
  const [ email, setEmail ] = useState("")
  const nav = useNavigate()

  const onReset = async () => {
    firebaseLog('reset', {
      method: 'email'
    })
    if (await authReset(email))
      nav('/auth/login')
  }

  return (
    <main className="container">
      <header className="text-center mt-10 text-2xl">
        <h2 className="font-[Montserrat] font-semibold">DARKO Studio Dashboard</h2>
      </header>
      <article className="shadow-none">
        <form className="sm:w-1/2 sm:mx-auto">
          <label className="pb-2">로그인시 사용한 이메일을 입력해주세요</label>

          <input type="email"
                 className="form-input rounded" placeholder="이메일"
                 value={ email }
                 onChange={ ({ target: { value } }) => setEmail(value) }
          />

          <button type="button" className="border-2 border-gray-600 font-semibold py-2" onClick={ onReset }>
            비밀번호 재설정 메일 전송
          </button>

          <div className="py-4 flex">
            <Link className="flex-1" to="/auth/login">로그인</Link>
            <Link className="flex-1 text-right" to="/auth/signup">회원가입</Link>
          </div>
        </form>
      </article>
      <Footer />
    </main>)
}