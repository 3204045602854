import { PropsWithChildren, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Dialog } from '@headlessui/react'
import { logout } from 'src/service/api.service'
import { setAuth } from 'src/Auth'
import { Bars3Icon, XCircleIcon } from '@heroicons/react/24/outline'

export let isNavbarOpen: boolean, setIsNavbarOpen: Function

export const WithNavbar = ({ children }: PropsWithChildren) => {
  [ isNavbarOpen, setIsNavbarOpen ] = useState(false)

  const Sidebar = () => {
    return (
      <nav className="fixed relative flex flex-col h-full">
        <ul className="space-y-2">
          <li className="mb-8">
            <div className="p-2 ml-3">
              <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">DARKO Studio</span>
            </div>
          </li>
          {/*<li>*/}
          {/*  <NavLink to="/dashboard" onClick={ () => setIsNavbarOpen(false) }>*/}
          {/*    <div*/}
          {/*      className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">*/}
          {/*      <span className="flex-1 ml-3">대시보드</span>*/}
          {/*    </div>*/}
          {/*  </NavLink>*/}
          {/*</li>*/}
          <li>
            <NavLink to="/dashboard/products" onClick={ () => setIsNavbarOpen(false) }>
              <div
                className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                <span className="flex-1 ml-3">서비스 소개</span>
              </div>
            </NavLink>
          </li>
          <li>
            <NavLink to="/dashboard/payments" reloadDocument={ true }>
              <div
                className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                <span className="flex-1 ml-3">결제 내역</span>
              </div>
            </NavLink>
          </li>
          <li></li>
        </ul>
        <div className="flex-auto"><br /></div>
        <NavLink to="/"
                 reloadDocument={ true }
                 onClick={ () => {
                   logout()
                   setAuth(null)
                 } }
                 className="flex items-center mb-6 p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
          <span className="flex-1 ml-3">로그아웃</span>
        </NavLink>
        <div className="flex-1"><br /></div>
      </nav>
    )
  }

  return (
    <div className="flex min-h-screen">
      <Dialog className="md:hidden fixed inset-0 z-10" open={ isNavbarOpen } onClose={ () => setIsNavbarOpen(false) }>
        <div className="relative z-20 h-full text-center">
          <div className="h-full px-3 py-10 overflow-y-auto rounded bg-gray-50 dark:bg-gray-800">
            <button type="button" value="Close sidebar"
                    className="border-none z-30 w-10 h-10 absolute top-2 right-2 flex items-center justify-center"
                    onClick={ () => setIsNavbarOpen(false) }
            >
              <XCircleIcon className="w-8 h-8" />
            </button>
            <Sidebar />
          </div>
        </div>
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50" aria-hidden="true"></div>
      </Dialog>
      <div className="hidden md:block w-64">
        <div className="px-3 py-4 h-screen overflow-y-auto rounded bg-gray-50 dark:bg-gray-800">
          <Sidebar />
        </div>
      </div>
      <main className="flex-1 overflow-y-auto container md:max-h-screen">
        <button type="button" value="Open sidebar"
                className="md:hidden border-none fill-white w-10 h-10 fixed top-2 right-2 flex items-center justify-center"
                onClick={ () => setIsNavbarOpen(true) }
        >
          <Bars3Icon className="w-8 h-8" />
        </button>
        { children }
      </main>
    </div>
  )
}