import { Navigate, Route, Routes } from 'react-router-dom'
import { WithNavbar } from 'src/page/dashboard/WithNavbar'
import { Home } from 'src/page/dashboard/Home'
import { Payments } from 'src/page/dashboard/Payments'
import { Products } from 'src/page/dashboard/Products'

export const Dashboard = () => {
  return (
    <Routes>
      <Route path="/*" element={ <WithNavbar><Navigate to='products' /></WithNavbar> } />
      <Route path="/products" element={ <WithNavbar><Products /></WithNavbar> } />
      <Route path="/payments" element={ <WithNavbar><Payments /></WithNavbar> } />
    </Routes>
  )
}