import { Footer } from 'src/layout/Footer'

export const Payments = () => {

  return (
    <>
      <header className="text-center mt-10 text-2xl">
        <h2 className="font-[Montserrat] font-semibold">Payments</h2>
      </header>
      <article className="shadow-none">

      </article>
      <Footer />
    </>)
}