import {
  AuthRequestData,
  AuthResponseData,
  firebaseCreateUser,
  firebaseLogin,
  firebasePasswordReset
} from 'src/service/firebase'

export const login = async ({ email, password }: AuthRequestData) => {
  try {
    const result = await firebaseLogin({ email, password })
    if (!result) return false

    const auth: AuthResponseData = {
      user: {
        email: result.user.email ?? '',
        emailVerified: result!.user.emailVerified
      },
      token: await result.user.getIdToken()
    }
    sessionStorage.setItem('auth', JSON.stringify(auth))

    return true
  } catch (e) {
    console.error(e)
    return false
  }
}

export const logout = () => {
  sessionStorage.removeItem('auth')
}

export const getAuth = (): AuthResponseData | null => {
  const authData = sessionStorage.getItem('auth')
  if (authData) return JSON.parse(authData)
  return null
}

export const signup = async ({ email, password }: AuthRequestData) => {
  try {
    const result = await firebaseCreateUser({ email, password })
    if (!result) return false

    const auth: AuthResponseData = {
      user: {
        email: result.user.email ?? '',
        emailVerified: result!.user.emailVerified
      },
      token: await result.user.getIdToken()
    }
    sessionStorage.setItem('auth', JSON.stringify(auth))
    return true
  } catch (e) {
    console.error(e)
    return false
  }
}

export const authReset = async (email: string) => {
  try {
    await firebasePasswordReset(email)
    return true
  } catch (e) {
    console.error(e)
    return false
  }
}

export const apiAction = async (data: object) => {
  const auth = getAuth()
  const response = await fetch('/api/login', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'authorization': 'Bearer ' + auth?.token ?? '',
    },
    body: JSON.stringify(data)
  })
  console.log(response.text())
}